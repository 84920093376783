<!-- <header class="header">
  <div class="container">
    <div class="nav-new">
      <div class="navbar-brand d-flex">
        <div class="logo-part">
          <a href="#">
            <img src="../../../assets/images/header/logo-header.png" alt="">
          </a>
        </div>
        <div class="mail-phone-div mt-3">
          <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="header-svg" viewBox="0 0 512 512">
              <path
                d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
            </svg> 02080409005</a>
          <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="header-svg" viewBox="0 0 512 512">
              <path
                d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
            </svg> support&#64;bookyup.com</a>
        </div>
      </div>
      <input class="menu-btn" type="checkbox" id="menu-btn" />
      <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
      <ul class="menu">
        <ul class="">
          <ng-container *ngIf="!isLoggedIn || guestlogin">
            <li>
              <a href="javascript:void(0)" class="menu__item" type="button" data-bs-toggle="modal"
                data-bs-target="#loginModal" (click)="loginpop(login)">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="header-svg">
                  <path
                    d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17l0 80c0 13.3 10.7 24 24 24l80 0c13.3 0 24-10.7 24-24l0-40 40 0c13.3 0 24-10.7 24-24l0-40 40 0c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z" />
                </svg>
                <p>Log In</p>
              </a>
            </li>
            <li>
              <a class="menu__item" type="button" data-bs-toggle="modal" data-bs-target="#rgModal"
                (click)="open(content)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    d="M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
                </svg>
                <p>Sign Up</p>
              </a>
            </li>
          </ng-container>
          <ng-container *ngIf="isLoggedIn && !guestlogin">
            <li class="nav-item">
              <a href="/account/details" class="nav-link">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z" />
                </svg>
                <p>MY ACCOUNT</p>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" class="nav-link" type="button" data-bs-toggle="modal"
                data-bs-target="#exampleModal2" (click)="logoutuser()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                </svg>
                <p>Log Out</p>
              </a>
            </li>
          </ng-container>
          <li>
            <a href="#" class="nav-link">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg>
              <p>Help</p>
            </a>
          </li>
        </ul>
      </ul>
    </div>
  </div>
</header> -->




<header id="nav">
  <nav id="navbar1" class="navbar navbar-expand-md navbar-dark">
    <div class="container">

      <div class="navbar-brand">
        <div class="logo-part">
          <a href="#">
            <img src="../../../assets/images/header/logo-header.png" alt="">
          </a>
        </div>
        <div class="mail-phone-div">
          <a href="#"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
            </svg> 02080409005</a>
          <a href="#"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
            </svg> support&#64;bookyup.com</a>
        </div>
      </div>

      <button class="navbar-toggler toggler-example" type="button" data-toggle="collapse"
        data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false"
        aria-label="Toggle navigation">
        <div class="navigation-bar">

          <input id="navigation-checkbox" class="navigation-checkbox" type="checkbox">
          <label class="navigation-toggle" for="navigation-checkbox">
            <span class="navigation-toggle-icon"></span>
          </label>
        </div>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent1">

        <ul class="menu-part nav navbar-nav ml-auto">
          <ng-container *ngIf="!isLoggedIn || guestlogin">
            <li>
              <a href="javascript:void(0)" class="menu__item" type="button" data-bs-toggle="modal"
                data-bs-target="#loginModal" (click)="loginpop(login)">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17l0 80c0 13.3 10.7 24 24 24l80 0c13.3 0 24-10.7 24-24l0-40 40 0c13.3 0 24-10.7 24-24l0-40 40 0c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z" />
                </svg>
                <p>Log In</p>
              </a>
            </li>
            <li>
              <a class="menu__item" type="button" data-bs-toggle="modal" data-bs-target="#rgModal"
                (click)="open(content)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    d="M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
                </svg>
                <p>Sign Up</p>
              </a>
            </li>
          </ng-container>
          <ng-container *ngIf="isLoggedIn && !guestlogin">
            <li class="nav-item">
              <a href="/account/details" class="nav-link">
                <app-svg-icon iconname="myaccount"></app-svg-icon>
                <p>MY ACCOUNT</p>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" class="nav-link" type="button" data-bs-toggle="modal"
                data-bs-target="#exampleModal2" (click)="logoutuser()">
                <app-svg-icon iconname="logout"></app-svg-icon>
                <p>Log Out</p>
              </a>
            </li>
          </ng-container>
          <li>
            <a href="#" class="nav-link">
              <app-svg-icon iconname="help-icon"></app-svg-icon>
              <p>Help</p>
            </a>
          </li>
        </ul>
      </div>
      <ng-template #login let-modal>
        <form [formGroup]="loginFormGroup" (ngSubmit)="loginForm()">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Log-In</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  (click)="modal.dismiss('Cross click')"></button>
              </div>
              <div class="modal-body go-to-checkout-modal">

                <div *ngIf="guestoption">

                  <div class="anchor-div row">
                    <div class="col-lg-6 left-modal">
                      <h5 class="mb-3">Returning <span>Customer</span></h5>

                      <div class="form-group">

                        <label class="mb-2">Enter Your Email</label>
                        <input class="form-control" [(ngModel)]="loguser.email" name="email" formControlName="email" />
                        <div class="alert alert-danger"
                          *ngIf="(loginFormValidate['email'].errors !=null && loginFormValidate['email'].errors['required'] && loginFormValidate['email'].touched)">
                          Username is required
                        </div>
                      </div>
                      <div class="form-group">

                        <label class="mt-2 mb-2">Enter Your Password</label>
                        <input type="password" class="form-control" name="password" [(ngModel)]="loguser.password"
                          formControlName="password" />
                        <div class="alert alert-danger"
                          *ngIf="(loginFormValidate['password'].errors !=null && loginFormValidate['password'].errors['required'] && loginFormValidate['password'].touched)">
                          Password is required
                        </div>
                      </div>
                      <div class="same-div"><button type="submit go-to-check-button"
                          class="btn btn-primary">Login</button></div>
                      <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal11" href="javascript:void(0)"
                        class="forget-pass">
                        Forget Password ?
                      </a>
                    </div>
                    <div class="col-lg-6 right-modal">
                      <h5>New <span>Customer</span></h5>
                      <div class="same-div same-div1">
                        <h6>Save time now</h6>
                        <p>You don't need an account to check out.</p>
                        <a (click)="createGuestlogin()" *ngIf="guestoption"> Continue As Guest</a>
                      </div>
                      <div class="same-div">
                        <h6>Save time later</h6>
                        <p>Create account for fast checkout and easy access to order history.</p>
                        <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal12"
                          (click)="callregistarmodal()"> Create An Account</a>
                      </div>

                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="right-modal">

                      <div class="social-icon">
                        <h6>Or You Can sign With</h6>
                        <a href="#">
                          <img src="../../../assets/images/menu/google.png" alt="">
                          Sign-In
                        </a>
                        <a href="#">
                          <img src="../../../assets/images/menu/facebook.png" alt="">
                          Sign-In
                        </a>
                      </div>

                    </div>

                  </div>
                </div>

                <div *ngIf="!guestoption">



                  <div class="mb-3">
                    <label>Email address</label>

                    <input class="form-control" name="email" formControlName="email" />
                    <div class="alert alert-danger"
                      *ngIf="(loginFormValidate['email'].errors !=null && loginFormValidate['email'].errors['required'] && loginFormValidate['email'].touched)">
                      Username is required
                    </div>
                  </div>
                  <div class="mb-3">
                    <label>Password</label>

                    <input type="password" class="form-control" name="password" formControlName="password" />
                    <div class="alert alert-danger"
                      *ngIf="(loginFormValidate['password'].errors !=null && loginFormValidate['password'].errors['required'] && loginFormValidate['password'].touched)">
                      Password is required
                    </div>
                  </div>
                  <div>
                    <div *ngIf="loader">
                      <img src="assets/images/loader.gif" style="width:100%;" />
                    </div>
                    <div *ngIf="!loader" class="login-reg">
                      <button type="submit" class="btn">Log In</button>
                      <a href="javascript:void(0)" id="forget-password" class="mb-2"
                        (click)="forgotpasswordmodal()">Forgot
                        your password?</a>
                      <a class="btn btn-prime" (click)="callregistarmodal()">REGISTER</a>
                    </div>

                  </div>
                  <p class="alert alert-danger" *ngIf="loginerror">{{loginerrortext}}</p>

                  <!-- <div class="or"><span>OR</span></div> -->

                  <div class="modal-footer">
                    <div class="row">
                      <div class="col-lg-6 mt-2">
                        <h6>Or use an account</h6>
                      </div>

                      <div class="col-lg-6 ">
                        <div class="lower-div">
                          <a href="javascript:void(0)">
                            <img src="../../../assets/images/menu/apple-logo.png" alt="">
                          </a>
                          <a href="javascript:void(0)">
                            <img src="../../../assets/images/menu/google.png" alt="">
                          </a>
                          <a href="javascript:void(0)">
                            <img src="../../../assets/images/menu/facebook.png" alt="">
                          </a>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>


              </div>
            </div>
          </div>
        </form>
      </ng-template>
      <ng-template #content let-modal>
        <form [formGroup]="registerForm" (ngSubmit)="registerFormSubmit()">
          <div class="modal-header">
            <h3 class="modal-title" id="modal-basic-title">Register</h3>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body myform" size="lg">
            <div class="row">
              <div class="col-md-12">
                <div class="fldarea">
                  <label>Name<span class="text-red">*</span></label>
                  <input class="form-control" name="firstName" formControlName="firstName" />
                  <div class="alert alert-danger"
                    *ngIf="(regValidator['firstName'].errors !=null && regValidator['firstName'].errors['required'] && regValidator['firstName'].touched)">
                    Name is required
                  </div>
                </div>
                <div class="fldarea">
                  <label>Phone Number<span class="text-red">*</span></label>
                  <input class="form-control" name="phone" formControlName="phoneNumber" />
                  <div class="alert alert-danger"
                    *ngIf="regValidator['phoneNumber'].errors !=null && regValidator['phoneNumber'].errors['required'] && regValidator['phoneNumber'].touched">
                    Please enter valid phone number
                  </div>
                  <div class="alert alert-danger"
                    *ngIf="regValidator['phoneNumber'].errors !=null && regValidator['phoneNumber'].errors['pattern'] && regValidator['phoneNumber'].touched">
                    Phone number must have 11 digit and a leading 0
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="fldarea">
                  <label>Email address<span class="text-red">*</span></label>
                  <input class="form-control" name="email" formControlName="email" required />
                  <div class="alert alert-danger"
                    *ngIf="regValidator['email'].errors !=null && regValidator['email'].errors['required'] && regValidator['email'].touched">
                    Email address is required
                  </div>
                  <div class="alert alert-danger"
                    *ngIf="regValidator['email'].errors !=null && regValidator['email'].errors['email'] && regValidator['email'].touched">
                    Please enter valid email
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="fldarea">
                  <label>Password<span class="text-red">*</span></label>
                  <input type="password" class="form-control" name="password" formControlName="password" required />
                  <div class="alert alert-danger"
                    *ngIf="regValidator['password'].errors !=null && regValidator['password'].errors['required'] && regValidator['password'].touched">
                    Password is required
                  </div>
                  <div class="alert alert-danger"
                    *ngIf="regValidator['password'].errors !=null && regValidator['password'].errors['minlength'] && regValidator['password'].touched">
                    Password should be atleast 6 charecter.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="fldarea">
                  <label>Confirm Password<span class="text-red">*</span></label>
                  <input type="password" class="form-control" name="cpassword" formControlName="cpassword" required />
                  <div class="alert alert-danger"
                    *ngIf="regValidator['cpassword'].errors !=null && regValidator['cpassword'].errors['required'] && regValidator['cpassword'].touched">
                    Confirm Password is required
                  </div>

                  <div class="alert alert-danger"
                    *ngIf="regValidator['cpassword'].errors !=null && regValidator['cpassword'].errors['passwordMatch'] && regValidator['cpassword'].touched">
                    Confirm Password Mismatched
                  </div>
                </div>
              </div>
            </div>
            <p class="alert alert-danger" *ngIf="regerror">{{regerrortext}}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <div *ngIf="loader">
              <img src="assets/images/loader.gif" style="width:100%;" />
            </div>
            <ng-container *ngIf="!loader">
              <div class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Close</div>
              <button type="submit" class="btn btn-dark">Create Account</button>
            </ng-container>
          </div>
        </form>
      </ng-template>

      <ng-template #emailVerify let-modal>
        <form #otpform="ngForm">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Email Verification</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h5 class="greenText" *ngIf="otpsendText !=''">{{otpsendText}}</h5>
            <div class="fldarea">
              <label></label>
              <input class="form-control" placeholder="Enter verification code here" name="otp" #emotp="ngModel"
                [(ngModel)]="verifyf.otp" required />
              <div class="alert alert-danger" *ngIf="(!emotp.value && otpform.submitted)">Verification code is required
              </div>
            </div>
            <p class="alert alert-danger" *ngIf="otperror">{{otperrortext}}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <a htef="javascript:void(0)" class="btn btn-outline-dark" (click)="resendOtp()">Resend Codes</a>
            <div *ngIf="loader">
              <img src="assets/images/loader.gif" style="width:100%;" />
            </div>
            <ng-container *ngIf="!loader">
              <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
              <button class="btn btn-prime" (click)="otpverify()">Submit</button>
            </ng-container>
          </div>
        </form>
      </ng-template>

      <ng-template #onetimeVerify let-modal>
        <form #onetimeotpform="ngForm" (ngSubmit)="oneverifycall(onetimeotpform.form.valid)">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Verification code <span class="text-red">*</span></h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h5 class="greenText" *ngIf="otpsendText !=''">{{otpsendText}}</h5>
            <div class="fldarea">
              <label></label>
              <input class="form-control" placeholder="Enter verification code here" name="otp" #onotp="ngModel"
                [(ngModel)]="oneloginpayload.otp" required />
              <div class="alert alert-danger" *ngIf="(!onotp.value && onetimeotpform.submitted)">Verification code is
                required</div>
            </div>
            <p class="alert alert-danger" *ngIf="otperror">{{otperrortext}}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <a htef="javascript:void(0)" class="btn btn-dark" (click)="reonelogincall()">Resend verification code</a>
            <div *ngIf="loader">
              <img src="assets/images/loader.gif" style="width:100%;" />
            </div>
            <ng-container *ngIf="!loader">
              <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
              <input type="submit" class="btn btn-dark" value="Submit">
            </ng-container>
          </div>
        </form>
      </ng-template>

      <ng-template #onetimelogin let-modal>
        <form #onelogin="ngForm" (ngSubmit)="onelogincall(onelogin.form.valid)">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Verification</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="fldarea">
              <label></label>
              <input class="form-control" placeholder="Enter Email or mobile" name="email" #otpinput="ngModel"
                [(ngModel)]="oneloginpayload.otp_input" required />
              <div class="alert alert-danger"
                *ngIf="(otpinput.invalid && otpinput.touched) || (otpinput.invalid && onelogin.submitted)">
                Email address or mobile is required
              </div>
            </div>
            <p class="alert alert-danger" *ngIf="loginerror">{{loginerrortext}}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <div *ngIf="loader">
              <img src="assets/images/loader.gif" style="width:100%;" />
            </div>
            <ng-container *ngIf="!loader">
              <div class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Close</div>
              <input type="submit" class="btn btn-dark" value="Submit">
            </ng-container>
          </div>
        </form>
      </ng-template>

      <ng-template #forgotpassword let-modal>
        <form #forgotpasswordform="ngForm"
          (ngSubmit)="forgotpasswordcall(forgotpasswordform.form.valid,forgotpasswordform)">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Forgot Password</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h5 class="greenText" *ngIf="forgotpasswordText !=''">{{forgotpasswordText}}</h5>
            <div class="fldarea">
              <label></label>
              <input class="form-control" placeholder="Enter email or mobile" name="forgotfield" #forgot="ngModel"
                [(ngModel)]="forgotfield" required />
              <div class="alert alert-danger" *ngIf="(forgot.invalid && forgotpasswordform.submitted)">
                Email address or mobile is required
              </div>
            </div>
            <p class="alert alert-danger" *ngIf="forgotpassworderror">{{forgotpassworderror}}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <div *ngIf="loader">
              <img src="assets/images/loader.gif" style="width:100%;" />
            </div>
            <ng-container *ngIf="!loader">
              <div class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</div>
              <input type="submit" class="btn btn-dark" value="Submit">
            </ng-container>
          </div>
        </form>
      </ng-template>

      <ng-template #common_modal let-modal>
        <div class="modal-header">
          <h3 class="modal-title d-flex justify-content-center" id="modal-basic-title">{{common_popup_title}}</h3>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body myform" size="lg">
          <div class="row">
            <div class="col-md-12">
              <p>{{common_popup_message}}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <div class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</div>
          <button type="button" class="btn btn-dark" (click)="modal.dismiss('Cross click')">OK</button>
        </div>
      </ng-template>
    </div>
  </nav>
</header>