import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrl: './svg-icon.component.scss'
})
export class SvgIconComponent {
  @Input() iconname: string = "";
  constructor(){

  }
  ngOnInit(){

  }
}
