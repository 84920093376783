import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})

export class EncrdecrService {

  private IV = '5796265456269274';
  private KEY = '9qukm6uy4nkqpd3e';

  constructor() { }


  newset(str:string){
    let key = CryptoJS.enc.Utf8.parse(this.KEY);// Secret key
    let iv= CryptoJS.enc.Utf8.parse(this.IV);//Vector iv
    let encrypted = CryptoJS.AES.encrypt(str, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
  }
  newget(str:string){

    let key = CryptoJS.enc.Utf8.parse(this.KEY);// Secret key
    let iv=CryptoJS.enc.Utf8.parse(this.IV);//Vector iv
    let decrypted = CryptoJS.AES.decrypt(str,key,{iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7});
    return decrypted.toString(CryptoJS.enc.Utf8);

  }
}
