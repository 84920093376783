import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root', useValue: false
})
export class AuthService {

  constructor(
    private router: Router,
    private localSt: LocalStorageService
  ) { }

  setSecureToken(secure_token: string) {
    this.localSt.store('loghash', secure_token);
  }

  // Set User Secure Token
  getSecureToken() {
    return this.localSt.retrieve('loghash');
  }

  // Check User is LoggedIn or not!
  isLoggednIn() {
    return this.getSecureToken() !== null;
  }

  // Logout method
  logout() {
    this.localSt.clear('loghash');
    //this.router.navigate(['']);
    //this.router.navigate(['login']);
  }

  calllogin(){
    this.localSt.store('calllogin', 'trueval');
  }
}
