import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
 public copyrightYear:any = '2024';
 constructor(){ }
 ngOnInit(){
  let d = new Date();
  this.copyrightYear = d.getFullYear();

 }
}
