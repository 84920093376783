import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CustomEventService {
  public open: boolean;
  private bannersubject = new Subject<any>();
  private loginsubject = new Subject<any>();
  private commonpopupsubject = new Subject<any>();
  private subject = new Subject<any>();
  private showguestoption = false;
  private homeredirect=true;
  constructor(public rts: Router) {
    this.open = false;
  }
  setBanner(url = '') {
    this.bannersubject.next({ bannerurl: url });
  }
  getBanner() {
    return this.bannersubject.asObservable();
  }
  setloginpop(param: any) {
    if (param == "on") {
      this.open = true;
    } else {
      this.open = false;
    }
    //this.openpopup();
    // console.log(this.open);
    this.subject.next({ text: this.open });
  }
  popupcall() {
    return this.subject.asObservable();
  }
  openpopup() {
    return this.open;
    /*return new Observable((observer) =>{
      observer.next(this.open);
    });*/
  }
  logincomplete() {
    this.showguestoption = false;
    this.loginsubject.next({ text: "login-complete" });
  }

  opencommonpopup(popuptitle: string, poptext: string, poptype: string) {
    console.log(popuptitle)
    console.log(poptext)
    console.log(poptype)
    this.commonpopupsubject.next({ popuptitle: popuptitle, poptext: poptext, poptype: poptype });
  }

  listencommonpopup() {
    return this.commonpopupsubject.asObservable();
  }

  setguesoption(setoption: boolean) {
    this.showguestoption = setoption;
  }

  getguesoption(){
    return this.showguestoption;
  }
  

  checklogincomplete() {
    return this.loginsubject.asObservable();
  }
  sethomeredirect(val:boolean){

    this.homeredirect=val;
  }
  gohomeredirect(){
    // console.log("redireccall");
    let str= this.rts.url;
    if(this.homeredirect){

      if(str.includes("checkout") || str.includes("deliveryaddress")){
        this.rts.navigate(["/"]);
      }
     
    }
  }
}
