<footer>
	<div class="footer-bootom">
		<div class="container">
			<div class="footer-bottom1">
				<div class="row">

					<div class="foot-second">
						<div class="container">
							<div class="row align-items-center">

								<div class="row">
									<div class="col-lg-4">
										<a href="#">
											<img src="../../../assets/images/footer/footer-logo.png" alt="" class="footer-logo">
										</a>
										<!-- <h6>About Us</h6> -->
										<p>We are one of the largest online food ordering and <br>booking platform in
											UK.
											We work as an intermediator, connecting food makers to food lovers. </p>




									</div>
									<div class="col-lg-2">
										<h6>Quick Links</h6>
										<ul>
											<li><a href="#">FAQ</a></li>
											<li><a href="/information/report">Report a problem</a></li>
											<li><a href="/information/business-signup">Business signup</a></li>
											<li><a href="#">About us</a></li>
											<li><a href="#"> Business benefit</a></li>
											<li><a href="/contact-us"> Contact us</a></li>

										</ul>
									</div>
									<div class="col-lg-3">
										<div class="social-div">
											<h6>Connected With Us</h6>
											<ul>

												<li><a href="#">
														<app-svg-icon iconname="facebook"></app-svg-icon>/bookyup.facebook
													</a></li>
												<li><a href="#">
														<app-svg-icon iconname="instragram"></app-svg-icon>
														/bookyup.instagram
													</a></li>
												<li><a href="#">
														<app-svg-icon iconname="xlogo"></app-svg-icon>
														/bookyup.twitter
													</a></li>


											</ul>
										</div>
									</div>
									<div class="col-lg-3">
										<h6>We Accept</h6>
										<div class="total-card">
											<img src="../../../assets/images/footer/1.png" alt="">
											<img src="../../../assets/images/footer/2.png" alt="">
											<img src="../../../assets/images/footer/3.png" alt="">
											<img src="../../../assets/images/footer/4.png" alt="">
											<img src="../../../assets/images/footer/5.png" alt="">
											<img src="../../../assets/images/footer/6.png" alt="">
											<img src="../../../assets/images/footer/7.png" alt="">
										</div>
									</div>
								</div>


							</div>
						</div>
					</div>

				</div>
				<div class="container foot-privacy">
					<div class="row slign-items-center Copyright-footer">
						<div class="col-lg-6 foot-copy">
							<p>Copyright © {{copyrightYear}} <a href="/">Bookyup.com</a> Limited.</p>
						</div>
						<div class="col-lg-6 foot-link">
							<a href="/information/terms-and-condition" rel="nofollow">Terms and Conditions</a>
							<span>|</span>
							<a href="/information/privacy-policy" rel="nofollow"> Privacy Policy </a>
							<span>|</span>
							<a href="/information/refund-policy" rel="nofollow">Refund Policy</a>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</footer>