import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";

@Injectable({
  providedIn: 'root'
})
export class CustomstorageService {

  constructor(
    private localstorage: LocalStorageService,
    private sessionstorage: SessionStorageService,
  ) { }

  
  
  public async setLocalstorage(key: string, value: any) {
    return await this.localstorage.store(key,value);
  }
  public async getLocalstorage(key: string) {
    return await this.localstorage.retrieve(key);
  }
  public async removeLocalstorage(key: string) {
    return await this.localstorage.clear(key);
  }
  public async clearLocalstorage(key: string) {
    return await this.localstorage.clear();
  }
  public observe(key: string){
    return this.localstorage.observe(key);
  }
  
  public async setSessionstorage(key: string, value: any) {
    return await this.sessionstorage.store(key,value);
  }
  public async getSessionstorage(key: string) {
    return await this.sessionstorage.retrieve(key);
  }
  public async removeSessionstorage(key: string) {
    return await this.sessionstorage.clear(key);
  }
  public async clearSessionstorage(key: string) {
    return await this.sessionstorage.clear();
  }
}
